import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import authReducer from "./reducers/authReducer";
import questionReducer from "./reducers/questionReducer";
import slideReducer from "./reducers/slideReducer";
import timerReducer from "./reducers/timerReducer";

const initialState = {};

const composeEnhancers = composeWithDevTools;

const reducers = combineReducers({
  auth: authReducer,
  question: questionReducer,
  slide: slideReducer,
  attemptTime: timerReducer,
});

const store = createStore(reducers, initialState, composeEnhancers(applyMiddleware(thunk)));

export default store;
