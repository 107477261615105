/* eslint-disable import/prefer-default-export */
export const appConfig = {
  apiKey: "AIzaSyBjdNgbIZUp41Yp7SsFomIqIg1xsyerll8",
  authDomain: "najlepszy-z-10.firebaseapp.com",
  projectId: "najlepszy-z-10",
  storageBucket: "najlepszy-z-10.appspot.com",
  messagingSenderId: "736310759569",
  appId: "1:736310759569:web:a656d45ff41e7c922b2f30",
  measurementId: "G-D61QJDLLZ1",
};
