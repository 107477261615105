import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import backgroundImage from "../../images/starBackground.png";
import Menu from "./Menu";
import LoggedUserIdentity from "./LoggedUserIdentity";

/**
 * Wrapper component that ties up the UI.
 *
 *
 */
const LayoutRoot = ({ children }) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="layout-wrapper" style={{ backgroundImage: `url(${backgroundImage})`, paddingBottom: "4rem" }}>
      <Menu />
      {user && user.email && <LoggedUserIdentity />}
      {children}
    </div>
  );
};

export default LayoutRoot;

LayoutRoot.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};
