import React from "react";
import { Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const RestrictedRoute = ({ path, children, forAdmin, exact }) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const isVisible = () => {
    if (isLoggedIn) {
      return forAdmin ? user.isAdmin : true;
    }
    return false;
  };

  return isVisible() ? (
    <Route exact={exact} path={path}>
      {children}
    </Route>
  ) : (
    <Redirect to="/" />
  );
};

RestrictedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  forAdmin: PropTypes.bool,
  exact: PropTypes.bool,
};

RestrictedRoute.defaultProps = {
  forAdmin: false,
  exact: false,
};

export default RestrictedRoute;
