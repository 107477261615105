import React from "react";
import { LayoutRoot, Spinner } from "../components/Shared";

const LoadingScreen = () => (
  <LayoutRoot>
    <div className="centered-wrapper" style={{ minHeight: "100vh" }}>
      <Spinner />
    </div>
  </LayoutRoot>
);

export default LoadingScreen;
