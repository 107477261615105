import { SET_ANSWERS, SET_QUESTIONS } from "../types/questionTypes";

const initialState = {
  questions: [],
  answers: [],
  startDate: undefined,
};

const questionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUESTIONS: {
      return {
        ...state,
        questions: action.payload,
      };
    }
    case SET_ANSWERS: {
      return {
        ...state,
        answers: action.payload,
      };
    }
    default:
      return state;
  }
};

export default questionReducer;
