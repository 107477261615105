import React from "react";
import { useSelector } from "react-redux";

/**
 * Component to display logged user identity.
 *
 * @example ../../docs/LoggedUserIdentity.md
 */

const LoggedUserIdentity = () => {
  // TODO: Update selector after Firebase integration
  const email = useSelector((state) => state.auth.user.email);

  return <span className="logged-user-id-container">{email}</span>;
};

export default LoggedUserIdentity;
