import { SET_CURRENT_SLIDE } from "../types/slideTypes";

const initialState = {
  currentSlide: 0,
};

const slideReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SLIDE:
      return {
        ...state,
        currentSlide: action.payload,
      };
    default:
      return state;
  }
};

export default slideReducer;
