import { SET_ATTEMPT_TIME } from "../types/timerTypes";

const initialState = {
  remainingAttemptTime: undefined,
};

const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ATTEMPT_TIME: {
      return {
        ...state,
        remainingAttemptTime: action.payload,
      };
    }
    default:
      return state;
  }
};

export default timerReducer;
