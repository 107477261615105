import React from "react";
import PropTypes from "prop-types";
import buttonImg from "../../images/shared/navigation-button.svg";

/**
 * Navigation button used to navigate through slides
 *
 * @example ../../docs/NavigationButton.md
 */
const NavigationButton = ({ type, handleButtonClick }) => (
  <button
    style={{ backgroundImage: `url(${buttonImg})` }}
    onClick={() => handleButtonClick()}
    className={`navigation-button navigation-button--${type}`}
    type="button"
  >
    Test
  </button>
);

export default NavigationButton;

NavigationButton.propTypes = {
  /** Button type - previous or next */
  type: PropTypes.oneOf(["previous", "next"]).isRequired,
  /** Function called after button click */
  handleButtonClick: PropTypes.func.isRequired,
};
