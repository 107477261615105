const EMAIL = "emailForSignIn";

export const setEmail = (email) => {
  window.localStorage.setItem(EMAIL, email);
};

export const getEmail = () => {
  const email = window.localStorage.getItem(EMAIL);
  window.localStorage.removeItem(EMAIL);
  return email;
};
