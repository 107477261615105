import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingScreen from "../../pages/LoadingScreen";
import RestrictedRoute from "./RestrictedRoute";

const Faq = React.lazy(() => import("../../pages/Faq"));
const Landing = React.lazy(() => import("../../pages/Landing"));
const Login = React.lazy(() => import("../../pages/Login"));
const NotFound = React.lazy(() => import("../../pages/NotFound"));
const Auth = React.lazy(() => import("../../pages/Auth"));
const Logout = React.lazy(() => import("../../pages/Logout"));
const Quiz = React.lazy(() => import("../../pages/Quiz"));
const Rules = React.lazy(() => import("../../pages/Rules"));
const Submission = React.lazy(() => import("../../pages/Submission"));
const AdminDashboard = React.lazy(() => import("../../pages/AdminDashboard"));
const AdminQuestionCreation = React.lazy(() => import("../../pages/AdminQuestionCreation"));
const Admins = React.lazy(() => import("../../pages/Admins"));
const Questions = React.lazy(() => import("../../pages/Questions"));
const AdminSubmissions = React.lazy(() => import("../../pages/AdminSubmissions"));

/**
 * Component used to define routes of react-router inside of the project.
 */
const Routes = () => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      <Route exact path="/">
        <Landing />
      </Route>
      <Route path="/faq">
        <Faq />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/auth">
        <Auth />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>

      {/* User routes */}
      <RestrictedRoute path="/rules">
        <Rules />
      </RestrictedRoute>
      <RestrictedRoute path="/quiz">
        <Quiz />
      </RestrictedRoute>
      <RestrictedRoute path="/submission">
        <Submission />
      </RestrictedRoute>

      {/* Admin routes */}
      <RestrictedRoute exact path="/admin" forAdmin>
        <AdminDashboard />
      </RestrictedRoute>
      <RestrictedRoute exact path="/admin/questions" forAdmin>
        <Questions />
      </RestrictedRoute>
      <RestrictedRoute path="/admin/questions/:id" forAdmin>
        <AdminQuestionCreation />
      </RestrictedRoute>
      <RestrictedRoute path="/admin/admins" forAdmin>
        <Admins />
      </RestrictedRoute>
      <RestrictedRoute path="/admin/submissions" forAdmin>
        <AdminSubmissions />
      </RestrictedRoute>

      {/* Default route */}
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  </Suspense>
);

export default Routes;
