import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AttemptTimer from "../Quiz/AttemptTimer";

/**
 * Component with links to other subpages.
 *
 * @example ../../docs/Menu.md
 */
const Menu = () => {
  const { user } = useSelector((state) => state.auth);
  const { attemptTime } = useSelector((state) => state);
  const [isQuizPage, setIsQuizPage] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsQuizPage(window.location.href.includes("quiz"));
    }
  }, []);

  const renderAdminLinks = () => (
    <Link className="navbar__item" to="/admin">
      Admin
    </Link>
  );

  const renderNavLinks = () => (
    <>
      <Link className="navbar__item" to="/">
        HOME
      </Link>
      <Link className="navbar__item" to="/faq">
        FAQ
      </Link>
      {user && user.isAdmin && renderAdminLinks()}
      {user && user.email && (
        <Link className="navbar__item" to="/logout">
          Wyloguj
        </Link>
      )}
    </>
  );

  return (
    <nav className="navbar">
      <div className="navbar__item navbar__item--timer">{attemptTime.remainingAttemptTime > 0 && <AttemptTimer />}</div>
      <div className="container">{!isQuizPage && renderNavLinks()}</div>
    </nav>
  );
};

export default Menu;
