/* eslint-disable import/prefer-default-export */
const dev = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "http://localhost:3000/auth",
  // This must be true.
  handleCodeInApp: true,
};

const prod = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "http://najlepszyz10.samorzad.p.lodz.pl/auth",
  // This must be true.
  handleCodeInApp: true,
};

export const actionCodeSettings = process.env.NODE_ENV === "development" ? dev : prod;
