import * as actionTypes from "../types/authTypes";
import { signIn, sendSignInEmail as sendEmail, signOut, autoSignIn } from "../../api/firebase";
import { getEmail, setEmail } from "../../api/storage";

const error = (errorMessage) => ({
  type: actionTypes.ERROR,
  error: errorMessage,
});

const signInEmailSent = (email) => ({
  type: actionTypes.SIGNIN_EMAIL_SENT,
  emailForSignIn: email,
});

const sendSignInEmailInternal = () => ({
  type: actionTypes.SEND_SIGNIN_EMAIL,
});

export const sendSignInEmail = (email) => async (dispatch) => {
  dispatch(sendSignInEmailInternal());
  try {
    await sendEmail(email);
    dispatch(signInEmailSent(email));
    setEmail(email);
  } catch (e) {
    dispatch(error(e.message));
  }
};

const changeAuth = () => ({
  type: actionTypes.AUTH_CHANGE,
});

const authSuccess = (id, email, isAdmin, hasPreviousAttempts) => ({
  type: actionTypes.AUTH_SUCCESS,
  email,
  id,
  isAdmin,
  hasPreviousAttempts,
});

export const autoLogin = (userData) => async (dispatch) => {
  dispatch(changeAuth());
  try {
    const { uid, email, isAdmin, hasPreviousAttempts } = await autoSignIn(userData);
    dispatch(authSuccess(uid, email, isAdmin, hasPreviousAttempts));
  } catch (e) {
    dispatch(error(e.message));
  }
};

export const login = (loginLink) => async (dispatch) => {
  const loginEmail = getEmail();
  dispatch(changeAuth());
  try {
    const { uid, email, isAdmin, hasPreviousAttempts } = await signIn(loginLink, loginEmail);
    dispatch(authSuccess(uid, email, isAdmin, hasPreviousAttempts));
  } catch (e) {
    dispatch(error(e.message));
  }
};

const logoutInternal = () => ({ type: actionTypes.LOGOUT });

export const logout = () => async (dispatch) => {
  dispatch(changeAuth());
  try {
    await signOut();
    dispatch(logoutInternal());
  } catch (e) {
    dispatch(error(e.message));
  }
};

export const setHasPreviousAttempts = () => ({
  type: actionTypes.SET_HAS_PREVIOUS_ATTEMPTS,
});
