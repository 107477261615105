import React, { useEffect } from "react";
import firebase from "firebase/app";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { autoLogin } from "../redux/actions/authActions";

const UserProvider = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(async () => {
    firebase.auth().onAuthStateChanged((userAuth) => {
      if (userAuth) {
        dispatch(autoLogin(userAuth));
      }
    });
  }, []);

  return <>{children}</>;
};

UserProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
};

export default UserProvider;
