/* eslint-disable no-unused-vars */
import firebase from "firebase/app";
import { actionCodeSettings } from "../config/firebase/authOnLinkConfig";
import { appConfig } from "../config/firebase/appConfig";
import "firebase/auth";
import "firebase/firestore";

const ARGUMENT_ERROR_CODE = "auth/argument-error";

let db;

export const initialize = () => {
  const app = firebase.initializeApp(appConfig);
  db = firebase.firestore(app);
};

export const getInitializedDb = () => db;

export const sendSignInEmail = async (email) => {
  await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);
};

export const checkForExistingSubmissions = async (email) => {
  const result = await db.collection("submissions").where("author.email", "==", email).get();
  return Boolean(result.docs.length);
};

const fetchUserWithRole = async (user) => {
  const result = await db.collection("admins").doc(user.email).get();
  const hasPreviousAttempts = await checkForExistingSubmissions(user.email);
  return {
    id: user.uid,
    email: user.email,
    isAdmin: !!result.data(),
    hasPreviousAttempts,
  };
};

const parseError = (error) => {
  if (ARGUMENT_ERROR_CODE === error.code) {
    return "Link do logowania jest przedawniony";
  }
  return "Nie udało się zalogować";
};

const setSessionPersistence = async () => firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

export const autoSignIn = async (userData) => {
  try {
    await setSessionPersistence();
    return fetchUserWithRole(userData);
  } catch (e) {
    const message = parseError(e);
    throw new Error(message);
  }
};

export const signIn = async (signInLink, expectedEmail) => {
  if (firebase.auth().isSignInWithEmailLink(signInLink)) {
    try {
      const result = await firebase.auth().signInWithEmailLink(expectedEmail, signInLink);
      await setSessionPersistence();
      return fetchUserWithRole(result.user);
    } catch (e) {
      const message = parseError(e);
      throw new Error(message);
    }
  }
  throw new Error("Link do logowania jest niepoprawny");
};

export const signOut = async () => firebase.auth().signOut();
