import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import setAttemptTime from "../../redux/actions/timerActions";

const AttemptTimer = () => {
  const { remainingAttemptTime } = useSelector((state) => state.attemptTime);
  const [remainingTime, setRemainingTime] = useState(0);
  const dispatch = useDispatch();

  const getMinutes = () => {
    const minutesCounter = Math.floor(remainingTime / 60);
    return String(minutesCounter).length === 1 ? `0${minutesCounter}` : minutesCounter;
  };

  const getSeconds = () => {
    const secondsCounter = remainingTime % 60;
    return String(secondsCounter).length === 1 ? `0${secondsCounter}` : secondsCounter;
  };

  useEffect(() => {
    let intervalId;

    if (remainingAttemptTime >= 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime + 1 <= remainingAttemptTime) {
            return prevTime + 1;
          }
          return prevTime;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
      dispatch(setAttemptTime(undefined));
    };
  }, []);

  return (
    <div className="navbar__item navbar__timer">
      <span>Czas podejścia: </span>
      <span className="minute">{getMinutes()}</span>
      <span>:</span>
      <span className="second">{getSeconds()}</span>
    </div>
  );
};

export default AttemptTimer;
