import {
  AUTH_SUCCESS,
  LOGOUT,
  SIGNIN_EMAIL_SENT,
  ERROR,
  SEND_SIGNIN_EMAIL,
  AUTH_CHANGE,
  SET_HAS_PREVIOUS_ATTEMPTS,
} from "../types/authTypes";

export const initialState = {
  emailForSignIn: null,
  user: {
    id: null,
    email: null,
    isAdmin: null,
    hasPreviousAttempts: null,
  },
  isLoggedIn: false,
  loading: false,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEND_SIGNIN_EMAIL:
      return {
        ...state,
        loading: true,
        user: null,
        isLoggedIn: false,
        error: null,
      };

    case SIGNIN_EMAIL_SENT:
      return {
        ...state,
        emailForSignIn: action.emailForSignIn,
        loading: false,
        error: null,
      };

    case AUTH_CHANGE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        emailForSignIn: null,
        user: {
          id: action.id,
          email: action.email,
          isAdmin: action.isAdmin,
          hasPreviousAttempts: action.hasPreviousAttempts,
        },
        isLoggedIn: true,
        loading: false,
        error: null,
      };

    case LOGOUT:
      return {
        ...state,
        emailForSignIn: null,
        user: null,
        isLoggedIn: false,
        loading: false,
        error: null,
      };

    case SET_HAS_PREVIOUS_ATTEMPTS: {
      return {
        ...state,
        user: {
          ...state.user,
          hasPreviousAttempts: true,
        },
      };
    }

    case ERROR:
      return {
        ...state,
        user: null,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default authReducer;
