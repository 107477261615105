import React from "react";
import { Transition } from "react-transition-group";
import PropTypes from "prop-types";

const defaultStyle = {
  transition: `transform 350ms, opacity 350ms ease-in`,
  opacity: 1,
};

const transitionStyles = {
  entering: { transform: "scale(0)", opacity: 0 },
  entered: { transform: "scale(1)", opacity: 1 },
  exiting: { opacity: 0.5 },
  exited: { opacity: 0 },
  unmounted: {},
};

/**
 * Component responsible for animation.
 * Children of this component are animated after inProp has been set to "true".
 *
 */
const AnimatedComponent = ({ inProp, children }) => (
  <Transition
    in={inProp}
    timeout={{
      appear: 200,
      enter: 300,
      exit: 100,
    }}
    appear
    unmountOnExit
  >
    {(state) => (
      <div
        style={{
          ...defaultStyle,
          ...transitionStyles[state],
        }}
      >
        {children}
      </div>
    )}
  </Transition>
);

export default AnimatedComponent;

AnimatedComponent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]).isRequired,
  inProp: PropTypes.bool.isRequired,
};
